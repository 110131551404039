import axios from "../axios";

/**
 * @deprecated since 04/12/23
 */
export const getProductData = async () => {
  var response = await axios.get("product/api/v1/products");
  return response.data;
};

// only for admin
export const getAllProducts = async (args) => {
  const response = await axios.get(`product/api/v1/products/admin-projection`, {
    params: {
      searchTerm: args?.searchTerm || "",
      size: args?.size || 100,
      page: args?.page || 0,
      sortBy: args?.sortBy || null,
    },
  });
  return response.data;
};

export const getProductById = async (id) => {
  var response = await axios.get(`product/api/v1/products/${id}`);
  return response.data;
};
export const updateProduct = async (id, args) => {
  var response = await axios.put(`product/api/v1/products/${id}`, args);
  return response.data;
};

export const sendProductSkuIds = async (args) => {
  var response = await axios.post(`/product/api/v1/products/names-for-skuids`,args);
  return response.data;
};
