import {
  Button,
  Center,
  Group,
  LoadingOverlay,
  ScrollArea,
  Table,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  ChevronDown,
  ChevronUp,
  Pencil,
  Plus,
  Search,
  Selector,
  Trash,
} from "tabler-icons-react";
import {
  fetchHighlightByIdThunk,
  updateHighlightThunk,
} from "../../store/slices/highlights";
import CustomLoader from "../common/CustomLoader";
import DeleteBanner from "../common/DeleteBanner";
import TableCell from "../common/TableCell";
import HighlightsLocalizationModal from "./HighlightsLocalizationModal";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor: theme.colors.colorLightGray,
    },
  },
  scrollArea: {
    height: "85vh",
  },
  searchAndButtonContainter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },
  buttonColumn: {
    width: "300px !important",
  },
  buttonContainer: {
    display: "flex",
    width: "280px",
    paddingRight: "16px",
    flexWrap: "wrap",
    "@media (max-width: 1150px)": {
      flexDirection: "column",
    },
  },
  searchInput: {
    width: "25vw",
    marginBottom: "0px !important",
  },
  searchInputMargin: {
    marginBottom: "0px",
  },
  insertButton: {
    alignSelf: "flex-end",
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  tableIconsLogo: {
    color: theme.colors.colorDarkGray,
    "&:hover": {
      color: theme.colors.colorBlack,
    },
  },
  tableIconsTrash: {
    color: theme.colors.colorRed,
    "&:hover": {
      color: theme.colors.colorRed,
    },
  },
  tableIconsButton: {
    padding: "0px",
    "&:hover": {
      backgroundColor: theme.colors.colorGray,
    },
  },
}));

const Th = ({ children, reversed, sorted, onSort }) => {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;

  return (
    <th className={classes.th}>
      <UnstyledButton className={classes.control} onClick={onSort}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
};

const HighlightsLocalizationTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const tableData = useSelector((state) => state.highlights.highlight || {});
  const statusById = useSelector(
    (state) => state?.teamMembers?.statusById || ""
  );

  const [updateData, setUpdateData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [deleteBannerVisible, setDeleteBannerVisible] = useState(false);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const closeModal = () => {
    setModalVisible(false);
    setUpdateData(null);
  };

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const closeDeleteBanner = () => {
    setDeleteBannerVisible(false);
  };
  const openModal = async (row) => {
    setUpdateData(row ? row : null);
    setModalVisible(true);
  };

  const deleteLocale = async () => {
    // Deep clone tableData to avoid direct mutation (consider if necessary)
    let obj = JSON.parse(JSON.stringify(tableData));

    // Iterate over localizations to find and delete the matching one
    for (const [langKey, value] of Object.entries(obj.localizations)) {
      if (JSON.stringify(value) === JSON.stringify(deleteData[langKey])) {
        delete obj.localizations[langKey];
        break; // Exit loop after finding the match
      }
    }

    try {
      await dispatch(updateHighlightThunk(obj)).unwrap();
      // Show success notification
      showNotification({
        message: t("highlights.delete.success"),
        color: "green",
      });
    } catch (e) {
      // Show error notification on failure
      showNotification({
        message: t("highlights.delete.error"),
        color: "red",
      });
    }

    // Fetch updated highlight by ID
    await dispatch(fetchHighlightByIdThunk(obj.id)).unwrap();
    closeDeleteBanner(); // Close the delete confirmation banner
  };

  /**
   * Filters table data based on a search term across multiple fields.
   * @param {Array} data - The data to be searched through.
   * @returns {Array} The filtered data based on the search term.
   */
  const searchTableData = (data) => {
    // Return early if there's no search term
    if (search.trim() === "") return data;

    const searchTerm = search.toLowerCase();

    return data.filter(([key, value]) => {
      // Check if any of the relevant fields contain the search term
      return (
        key.toLowerCase().includes(searchTerm) ||
        value.title.toLowerCase().includes(searchTerm) ||
        value.description.toLowerCase().includes(searchTerm) ||
        value.category.toLowerCase().includes(searchTerm)
      );
    });
  };

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  const sortTableData = (tableData) => {
    // Ensure there's data to sort
    if (!tableData?.localizations) return [];

    const entriesArray = Object.entries(tableData.localizations);

    // Determine the property to sort by based on sortBy value
    const getSortValue = (entry) => {
      if (sortBy === "locale") return entry[0].toLowerCase();
      return entry[1][sortBy]?.toLowerCase() || "";
    };

    // Sort the entries array
    entriesArray.sort((a, b) => {
      const valueA = getSortValue(a);
      const valueB = getSortValue(b);

      return reverseSortDirection
        ? valueB.localeCompare(valueA)
        : valueA.localeCompare(valueB);
    });

    return entriesArray;
  };

  const rows = searchTableData(sortTableData(tableData))?.map(
    ([key, value]) => (
      <tr key={key}>
        {TableCell(key)}
        {TableCell(value?.category)}
        {TableCell(value?.title)}
        {TableCell(value?.description)}
        <td className={classes.buttonColumn}>
          <Group position="right" className={classes.buttonContainer}>
            <Tooltip label={t("tooltips.edit")}>
              <Button
                className={classes.tableIconsButton}
                variant="subtle"
                onClick={() => {
                  openModal({ [key]: value });
                }}
              >
                <Pencil className={classes.tableIconsLogo} />
              </Button>
            </Tooltip>
            <Tooltip label={t("tooltips.delete")}>
              <Button
                variant="subtle"
                className={classes.tableIconsButton}
                onClick={() => {
                  setDeleteBannerVisible(true);

                  setDeleteData({ [key]: value });
                }}
              >
                <Trash className={classes.tableIconsTrash} />
              </Button>
            </Tooltip>
          </Group>
        </td>
      </tr>
    )
  );

  return (
    <>
      <ScrollArea className={classes.scrollArea}>
        <div className={classes.searchAndButtonContainter}>
          <TextInput
            className={classes.searchInput}
            placeholder={t("table.search")}
            mb="md"
            icon={<Search size={14} />}
            value={search}
            onChange={handleSearchChange}
          />
          <Button
            className={classes.insertButton}
            style={{ alignSelf: "flex-end" }}
            onClick={() => openModal()}
          >
            <Plus size={18} />
          </Button>
        </div>
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <thead>
            <tr>
              <Th
                sorted={sortBy === "locale"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("locale")}
              >
                {t("highlights.locale")}
              </Th>
              <Th
                sorted={sortBy === "category"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("category")}
              >
                {t("highlights.category")}
              </Th>
              <Th
                sorted={sortBy === "title"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("title")}
              >
                {t("highlights.title")}
              </Th>
              <Th
                sorted={sortBy === "description"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("description")}
              >
                {t("highlights.description")}
              </Th>
              <th className={classes.buttonColumn}></th>
            </tr>
          </thead>
          <tbody>
            {statusById === "pending" && (
              <tr>
                <td style={{ borderBottom: "none" }}>
                  <LoadingOverlay loader={CustomLoader()} visible />
                </td>
              </tr>
            )}
            {rows.length ? (
              rows
            ) : (
              <tr>
                <td colSpan={3}>
                  <Text weight={500} align="center">
                    {t("table.nothingFound")}
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <HighlightsLocalizationModal
        opened={modalVisible}
        onClose={closeModal}
        updateData={updateData}
      />
      <DeleteBanner
        deleteMethod={deleteLocale}
        centered={true}
        opened={deleteBannerVisible}
        onClose={closeDeleteBanner}
        message={t("deleteBanner.message")}
        okButtonText={t("deleteBanner.okButton")}
        cancelButtonText={t("deleteBanner.cancelButton")}
      />
    </>
  );
};

export default HighlightsLocalizationTable;
