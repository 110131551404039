import { useForm, yupResolver } from "@mantine/form";
import {
  createStyles,
  Modal,
  Button,
  TextInput,
  Select,
  Box,
} from "@mantine/core";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { showNotification } from "@mantine/notifications";
import { Editor } from "@tinymce/tinymce-react";
import {
  fetchProductByIdThunk,
  updateProductThunk,
} from "../../store/slices/product";
import {
  languageLocaleData,
  parseLanguageNames,
} from "../common/parseLanguageNames";
import config from "../../config";
const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",

    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
  },
  errorText: {
    color: "#f03e3e",
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },

  switch: {
    color: theme.colors.colorBlack,
    "& *": {
      cursor: "pointer",
    },
  },

  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  title: {
    lineHeight: 1,
  },
  item: {
    "& + &": {
      borderTop: `1px solid ${theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[2]
        }`,
    },
  },
  modalTitle: {
    fontWeight: "bold",
  },
  selectInput: {
    marginTop: 20,
    zIndex: 2,
  },
}));

const ProductsLocalizationModal = ({
  opened,
  onClose,
  updateData,
  isUpdate,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state?.product?.productData);
  const languageData = useSelector((state) => state?.language?.tableData);
  let params = useParams();

  const schema = Yup.object().shape({
    name: Yup.string()
      .trim(t("productModal.whitespaceValidation"))
      .strict(true)
      .required(t("productModal.categoryValidation"))
      .min(1, t("productModal.nameValidation"))
      .max(255, t("productModal.stringLenghtValidation")),
    locale: Yup.string().min(2, t("productModal.codeValidation")),
    description: Yup.string().test(
      "description",
      t("productModal.descriptionValidation"),
      function (value) {
        return value === "" ? false : true;
      }
    ),
    shortDescription: Yup.string()
      .trim(t("productModal.whitespaceValidation"))
      .strict(true)
      .required(t("productModal.shortDescriptionRequired"))
      .max(1000, t("productModal.shortDescriptionLengthValidation")),
  });

  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      shortDescription: "",
      locale: "",
    },
    validate: yupResolver(schema),
  });
  const prepareItemForInsert = (data, itemForUpdate) => {
    let descriptions = [];
    let names = [];
    let shortDescriptions = [];
    if (itemForUpdate.descriptions !== []) {
      descriptions = itemForUpdate.descriptions.filter(
        (item) => item.locale !== data.locale
      );
    }
    descriptions = [
      ...descriptions,
      { locale: data.locale, description: data.description },
    ];
    if (itemForUpdate.shortDescriptions !== []) {
      shortDescriptions = itemForUpdate.shortDescriptions.filter(
        (item) => item.locale !== data.locale
      );
    }
    shortDescriptions = [
      ...shortDescriptions,
      { locale: data.locale, description: data.shortDescription },
    ];
    if (itemForUpdate.names !== []) {
      names = itemForUpdate.names.filter((item) => item.locale !== data.locale);
    }
    names = [...names, { locale: data.locale, name: data.name }];
    var objUpdate = { ...itemForUpdate };
    objUpdate["names"] = names;
    objUpdate["descriptions"] = descriptions;
    objUpdate["shortDescriptions"] = shortDescriptions;
    return objUpdate;
  };
  const submitForm = async (data) => {
    var updateObject = [];
    var updateData = [];
    await dispatch(fetchProductByIdThunk(params.productId))
      .unwrap()
      .then((response) => {
        updateData = response;
      })
      .catch((e) => {
        showNotification({
          message: t("productLocalizationModal.saveFailed"),
          color: "red",
        });
      });
    if (isUpdate) {
      updateObject = prepareItemForInsert(data, updateData);
      await dispatch(
        updateProductThunk({ updateData: updateObject, updateObject })
      )
        .unwrap()
        .then(() => {
          showNotification({
            message: t("productLocalizationModal.updateSuccessfull"),
            color: "green",
          });
          form.reset();
          onClose();
        })
        .catch((e) => {
          showNotification({
            message: t("productLocalizationModal.updateFailed"),
            color: "red",
          });
        });
    } else {
      updateObject = prepareItemForInsert(data, updateData);
      await dispatch(updateProductThunk({ updateData, updateObject }))
        .unwrap()
        .then(() => {
          showNotification({
            message: t("productLocalizationModal.updateSuccessfull"),
            color: "green",
          });
          form.reset();
          onClose();
        })
        .catch((e) => {
          showNotification({
            message: t("productLocalizationModal.updateFailed"),
            color: "red",
          });
        });
    }
    await dispatch(fetchProductByIdThunk(updateData.id));
  };

  useEffect(() => {
    form.clearErrors();
    if (isUpdate) {
      form.setValues({
        description: updateData.description,
        shortDescription: updateData.shortDescription,
        name: updateData.name,
        locale: updateData.locale,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  return (
    <Modal
      size={"xl"}
      closeOnClickOutside={false}
      centered
      opened={opened}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title={t("productLocalizationModal.title")}
      classNames={{ title: classes.modalTitle }}
    >
      <form onSubmit={form.onSubmit(submitForm)}>
        <Select
          style={{ marginBottom: 20, zIndex: 100 }}
          styles={(theme) => ({
            item: {
              // applies styles to selected item
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: "#e7f5ff",
                  color: theme.colors.colorDarkGray,
                },
              },
            },
          })}
          className={classes}
          disabled={isUpdate}
          data={
            isUpdate
              ? languageData.map((i) => ({
                value: i?.code,
                label:
                  i?.code +
                  " - " +
                  i?.names
                    .filter((n) => n?.locale === "en")
                    .map(({ name }) => name),
              }))
              : languageLocaleData(languageData, tableData)
          }
          label={t("currencyLocaleModal.locale")}
          placeholder={t("currencyModal.codeInputPlaceholder")}
          classNames={classes}
          searchable
          {...form.getInputProps("locale")}
        />
        <TextInput
          classNames={classes}
          label={t("productLocalizationModal.nameLabel")}
          placeholder={t("productLocalizationModal.namePlaceholder")}
          {...form.getInputProps("name")}
        />
        <TextInput
          classNames={classes}
          label={t("productLocalizationModal.shortDescriptionLabel")}
          placeholder={t("productLocalizationModal.shortDescriptionPlaceholder")}
          {...form.getInputProps("shortDescription")}
        />

        <Box style={{ marginTop: 18 }}>
          <Editor
            apiKey={config.REACT_APP_TINY_MCE_EDITOR_API_KEY}
            value={form?.values?.description}
            onEditorChange={(content) =>
              form.setFieldValue("description", content)
            }
            init={{
              selector: "textarea",
              menubar: false,
              plugins: "link media advlist lists",
              toolbar:
                "bold italic | alignleft aligncenter alignright | numlist bullist outdent indent | link",

              branding: false,
            }}
          />
          {form.errors.description !== null ? (
            <p className={classes.errorText}>{form.errors.description}</p>
          ) : (
            " "
          )}
        </Box>
        <div className={classes.buttonContainer}>
          <Button type="submit" className={classes.insertButton}>
            {t("modalCommon.saveButton")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ProductsLocalizationModal;
