import {
  Box,
  Card,
  createStyles,
  Divider,
  SimpleGrid,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatCurrency, toReadableString } from "../util";

const useStyles = createStyles((theme) => ({
  root: { marginTop: 10 },
  mainContainer: {
    height: "auto",
    borderRadius: 12,
    borderStyle: "outset",
    display: "flex",
    flexDirection: "column",
    padding: 20,
    border: "1px solid #c9c9c9",
  },
  input: {
    height: "auto",
    paddingTop: 18,
  },
  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

export const CardOrderDetails = (props) => {
  const { classes } = useStyles();

  const deliveryDataById =
    useSelector((state) => state?.deliveryMethod?.deliveryMethodData) || [];
  const officesById =
    useSelector((state) => state?.office?.officesDataById) || {};

  const {
    orderNumber,
    createdAt,
    paymentMethod,
    totalAmount,
    totalPriceCurrency,
    deliveryAddress,
    deliveryStoreId,
    note,
    source,
  } = props;
  const { t } = useTranslation();
  const getAddressString = (addressObj) => {
    if (!addressObj) {
      return "";
    }

    const { street, houseNumber, postalCode, place, countryCode } = addressObj;
    return `${street} ${houseNumber}, ${postalCode} ${place}, ${countryCode}`;
  };

  return (
    <>
      <Divider
        size="xl"
        my="xs"
        label={t("cardOrderDetails.title")}
        labelPosition="center"
      />
      <SimpleGrid cols={2}>
        <Card withBorder>
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardOrderDetails.orderNumber")}
            defaultValue={orderNumber}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardOrderDetails.source")}
            defaultValue={source}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardOrderDetails.createdAt")}
            defaultValue={createdAt}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardOrderDetails.paymentMethodCode")}
            defaultValue={toReadableString(paymentMethod)}
          />
        </Card>
        <Card withBorder>
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardOrderDetails.totalAmount")}
            defaultValue={formatCurrency(
              Number(totalAmount),
              totalPriceCurrency
            )}
          />
          <TextInput
            classNames={classes}
            readOnly
            label={t("cardOrderDetails.deliveryMethodCode")}
            defaultValue={toReadableString(deliveryDataById.type)}
          />
          {deliveryAddress ? (
            <TextInput
              classNames={classes}
              readOnly
              label={t("cardOrderDetails.deliveryAddress")}
              defaultValue={getAddressString(deliveryAddress)}
            />
          ) : null}
          {deliveryStoreId ? (
            <TextInput
              classNames={classes}
              readOnly
              label={t("cardOrderDetails.pickUpAddress")}
              defaultValue={getAddressString(officesById)}
            />
          ) : null}
        </Card>
      </SimpleGrid>
    </>
  );
};
