import {
  Box,
  Button,
  Group,
  Image,
  Modal,
  Select,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TrashX, Upload } from "tabler-icons-react";
import * as Yup from "yup";
import { createFileThunk } from "../../store/slices/file";
import {
  createHighlightThunk,
  fetchHighlightsThunk,
  updateHighlightThunk,
} from "../../store/slices/highlights";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  dropzoneHidden: {
    display: "none",
  },
  errorText: {
    color: "#f03e3e",
    fontSize: "12px",
  },
  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));

const urlRegex =
  /^https:\/\/[a-z0-9\-_\.]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9\-_#\+\?\%\*\$&]+)*\/?(\?[a-zA-Z0-9\-_]+=[a-zA-Z0-9\-_+%*\$&]+&?)?$/;

const HighlightsModal = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { opened, onClose, isUpdate, updateData, tableData } = props;

  const [mobilePhotoUploading, setMobilePhotoUploading] = useState(false);
  const [desktopPhotoUploading, setDesktopPhotoUploading] = useState(false);

  const statusValues = useMemo(
    () => [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "Inactive" },
    ],
    []
  );

  const schema = Yup.object().shape({
    category: Yup.string()
      .trim(t("highlights.validations.categoryTrim"))
      .max(255, t("highlights.validations.categoryMaxLength"))
      .nullable(),
    title: Yup.string()
      .required(t("highlights.validations.titleRequired"))
      .trim(t("highlights.validations.titleTrim"))
      .strict(true)
      .max(255, t("highlights.validations.titleMaxLength")),
    description: Yup.string()
      .trim(t("highlights.validations.descriptionTrim"))
      .strict(true)
      .max(1024, t("highlights.validations.descriptionMaxLength"))
      .nullable(),
    ordinal: Yup.number()
      .typeError(t("highlights.validations.ordinalNumberValid"))
      .required(t("highlights.validations.ordinalNumberRequired")),
    detailUrl: Yup.string()
      .matches(urlRegex, t("highlights.validations.validUrl"))
      .trim(t("highlights.validations.detailUrlTrim"))
      .strict(true)
      .max(1024, t("highlights.validations.detailUrlMaxLength")),
    desktopPhotoUrl: Yup.string().required(
      t("highlights.validations.desktopPhotoUrlRequired")
    ),
    mobilePhotoUrl: Yup.string().nullable(),
    status: Yup.string().required(t("highlights.validations.statusRequired")),
  });

  const form = useForm({
    initialValues: {
      category: "",
      title: "",
      description: "",
      detailUrl: "",
      desktopPhotoUrl: "",
      mobilePhotoUrl: "",
      status: "INACTIVE",
      ordinal: tableData.length
        ? tableData[tableData.length - 1].ordinal + 1
        : 1,
    },
    validate: yupResolver(schema),
  });

  const uploadImage = async (image, imageType) => {
    const formData = new FormData();
    formData.append("file", image[0], image[0].name);

    if (imageType === "desktop") setDesktopPhotoUploading(true);
    if (imageType === "mobile") setMobilePhotoUploading(true);

    const response = await dispatch(createFileThunk(formData)).unwrap();

    if (imageType === "desktop") {
      form.setValues({ desktopPhotoUrl: response["x-direct-location"] });
      setDesktopPhotoUploading(false);
    } else if (imageType === "mobile") {
      form.setValues({ mobilePhotoUrl: response["x-direct-location"] });
      setMobilePhotoUploading(false);
    }
  };

  const submitForm = async (data) => {
    const notify = (success) => {
      showNotification({
        message: t(
          success ? "highlights.submit.success" : "highlights.submit.error"
        ),
        color: success ? "green" : "red",
      });
    };

    try {
      if (updateData && isUpdate) {
        // Ensure localizations exists
        data.localizations = data?.localizations || {};
        await dispatch(updateHighlightThunk(data)).unwrap();
      } else {
        // Prepare object with guaranteed localizations
        const obj = { ...data, localizations: data?.localizations || {} };
        await dispatch(createHighlightThunk(obj)).unwrap();
      }

      // If the operation is successful
      notify(true);
    } catch (error) {
      // If the operation fails
      notify(false);
    }

    // Fetch highlights and reset form
    await dispatch(fetchHighlightsThunk()).unwrap();
    form.reset();
    onClose();
  };

  useEffect(() => {
    form.clearErrors();
    form.reset();
    if (isUpdate) {
      form.setValues({
        id: updateData?.id || "",
        category: updateData?.category || "",
        title: updateData?.title || "",
        description: updateData?.description || "",
        detailUrl: updateData?.detailUrl || "",
        desktopPhotoUrl: updateData?.desktopPhotoUrl || "",
        mobilePhotoUrl: updateData?.mobilePhotoUrl || "",
        status: updateData?.status || "",
        ordinal: updateData?.ordinal || Infinity,
      });
    }
  }, [updateData]);

  return (
    <>
      <Modal
        closeOnClickOutside={false}
        centered
        size="xl"
        opened={opened}
        onClose={() => {
          form.clearErrors();
          form.reset();
          onClose();
        }}
        title={
          isUpdate
            ? t("highlights.modalTitleEdit")
            : t("highlights.modalTitleAdd")
        }
        sx={() => ({
          ".mantine-Modal-title": {
            fontWeight: "bold",
          },
        })}
      >
        <form onSubmit={form.onSubmit(submitForm)}>
          <TextInput
            label={t("highlights.category")}
            placeholder={t("highlights.category")}
            classNames={classes}
            {...form.getInputProps("category")}
          />
          <TextInput
            label={t("highlights.detailUrl")}
            placeholder={t("highlights.detailUrl")}
            classNames={classes}
            {...form.getInputProps("detailUrl")}
          />
          <TextInput
            label={t("highlights.title")}
            placeholder={t("highlights.title")}
            classNames={classes}
            {...form.getInputProps("title")}
          />

          <TextInput
            label={t("highlights.description")}
            placeholder={t("highlights.description")}
            classNames={classes}
            {...form.getInputProps("description")}
          />
          <Select
            styles={(theme) => ({
              item: {
                // applies styles to selected item
                "&[data-selected]": {
                  "&, &:hover": {
                    backgroundColor: "#e7f5ff",
                    color: theme.colors.colorDarkGray,
                  },
                },

                // applies styles to hovered item (with mouse or keyboard)
                "&[data-hovered]": {},
              },
            })}
            classNames={classes}
            data={statusValues}
            label={t("highlights.status")}
            placeholder={t("highlights.status")}
            {...form.getInputProps("status")}
          />
          <TextInput
            label={t("highlights.ordinalNumber")}
            placeholder={t("highlights.ordinalNumber")}
            classNames={classes}
            {...form.getInputProps("ordinal")}
          />
          <Box style={{ marginTop: 20 }}>
            <Dropzone
              styles={{
                root: {
                  marginTop: 10,
                  height: 250,
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "center",
                },
                inner: { pointerEvents: "all" },
              }}
              loading={desktopPhotoUploading}
              maxSize={1000000}
              onDrop={(i) => uploadImage(i, "desktop")}
              onReject={() =>
                showNotification({
                  message: t("mediaAssets.imgSizeError"),
                  color: "red",
                })
              }
              accept={IMAGE_MIME_TYPE}
            >
              <>
                {!form.values.desktopPhotoUrl ? (
                  <Group position="center" spacing="xl">
                    <Upload size={50} />

                    <div>
                      <Text size="xl" inline>
                        {t("highlights.desktopPhoto")}
                      </Text>
                      <Text size="xl" inline mt={7}>
                        {t("modalCommon.dropzone_msg_1")}
                      </Text>
                      <Text size="sm" color="dimmed" mt={7}>
                        {t("modalCommon.dropzone_msg_2")}
                      </Text>
                    </div>
                  </Group>
                ) : (
                  <Box>
                    {
                      <>
                        <Image
                          style={{ position: "relative" }}
                          src={form.values.desktopPhotoUrl}
                          height={200}
                          width={300}
                        />
                        <Text size="sm" color="dimmed" mt={7}>
                          {t("highlights.desktopPhoto")}
                        </Text>
                      </>
                    }{" "}
                    {!updateData && (
                      <Button
                        className={classes.tableIconsButton}
                        onClick={(e) => {
                          e.stopPropagation();

                          form.setValues({ desktopPhotoUrl: "" });
                        }}
                      >
                        <TrashX className={classes.tableIconsTrash} />
                      </Button>
                    )}
                  </Box>
                )}
              </>
            </Dropzone>
            {form.errors.desktopPhotoUrl && (
              <small className={classes.errorText}>
                {form.errors.desktopPhotoUrl}
              </small>
            )}
          </Box>
          <Box style={{ marginTop: 20 }}>
            <Dropzone
              styles={{
                root: {
                  marginTop: 10,
                  height: 250,
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "center",
                },
                inner: { pointerEvents: "all" },
              }}
              loading={mobilePhotoUploading}
              maxSize={1000000}
              onDrop={(i) => uploadImage(i, "mobile")}
              onReject={() =>
                showNotification({
                  message: t("mediaAssets.imgSizeError"),
                  color: "red",
                })
              }
              accept={IMAGE_MIME_TYPE}
            >
              <>
                {!form.values.mobilePhotoUrl ? (
                  <Group position="center" spacing="xl">
                    <Upload size={50} />

                    <div>
                      <Text size="xl" inline>
                        {t("highlights.mobilePhoto")}
                      </Text>
                      <Text size="xl" inline mt={7}>
                        {t("modalCommon.dropzone_msg_1")}
                      </Text>
                      <Text size="sm" color="dimmed" inline mt={7}>
                        {t("modalCommon.dropzone_msg_2")}
                      </Text>
                    </div>
                  </Group>
                ) : (
                  <Box>
                    {
                      <>
                        <Image
                          style={{ position: "relative" }}
                          src={form.values.mobilePhotoUrl}
                          height={200}
                          width={300}
                        />
                        <Text size="sm" color="dimmed" mt={7}>
                          {t("highlights.mobilePhoto")}
                        </Text>
                      </>
                    }{" "}
                    {!updateData && (
                      <Button
                        className={classes.tableIconsButton}
                        onClick={(e) => {
                          e.stopPropagation();

                          form.setValues({ mobilePhotoUrl: "" });
                        }}
                      >
                        <TrashX className={classes.tableIconsTrash} />
                      </Button>
                    )}
                  </Box>
                )}
              </>
            </Dropzone>
            {form.errors.mobilePhotoUrl && (
              <small className={classes.errorText}>
                {form.errors.mobilePhotoUrl}
              </small>
            )}
          </Box>
          <div className={classes.buttonContainer}>
            <Button className={classes.insertButton} type="submit">
              {t("modalCommon.saveButton")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default HighlightsModal;
